<template>
  <div class="v4-about-container">
    <div class="about-title scrollObj">
      <p>BLVD.International.</p>
    </div>
    <div class="about-banner-container scrollObj" data-extend="shrink"></div>
    <div class="about-content-container scrollObj" data-extend="logodown">
      <div class="content__children scrollObj">
        <p v-if="$bowserMobile">
          The High-End Brand<br />Of International<br />Environmental Design
        </p>
        <p v-else>The High-End Brand Of International Environmental Design</p>
        <p>国际高端环境设计品牌</p>
      </div>
      <div class="content__children scrollObj">
        <p>
          毕路德由杜昀先生和刘红蕾女士于2001年共同创立。“始于简单，止于至善”，源于对“至善和谐”理念的共同追求，成立以来，毕路德创作出一系列情、景、境相融合的设计精品，
          以国际化的现代语言重新诠释东方审美中推崇备至的“意境”之美，
          并通过自然、空间与人的相互对话，为观者带来无限遐思。兼具灵动的创意与严谨的学术精神，
          开创“建筑、景观、室内”三位一体的全新格局，毕路德不仅在酒店设计与办公设计方面表现出类拔萃，亦是公认的中国最优秀的滨水设计专家。
        </p>
        <p>
          BLVD was jointly established by Mr. Yun Du and Ms. Honglei Liu in 2001. ‘To start with
          simplification; to end with perfection’ conveys their shared pursuit of ‘perfectionism and
          harmony’. Over the past years since establishment, BLVD has created a series of excellent
          works that perfectly combine scene, emotion and artistic conception. BLVD re-interprets
          the beauty of ‘artistic conception’ that is highly praised in oriental aesthetics through
          internationalized and modernized language and further brings infinite imagination for
          visitors through the dialogue between nature, space and man. Embracing intelligent
          creativity and precise academic spirits, BLVD has become a trinity of architecture,
          landscape and interior design. BLVD does not only show excellence in hotel and office
          design, but is also acknowledged as the best expert in Chinese waterfront design industry.
        </p>
        <p>
          2016年，毕路德作为中国环境设计高端品牌，更吸引了启迪设计集团（300500）入股，
          双方通过各领域资源的整合，精致提供以文化创意为设计核心，具备开展建筑工程设计全业务流程资质和建设工程领域集成技术的高端一体化服务体系。
        </p>
        <p>
          In 2016, as a high-end brand of Chinese environmental design, BLVD successfully attracted
          Tus-Design Group Co., Ltd (300500) to buy its stocks. BLVD and Tus-Design Group integrate
          resources in various fields aiming at establishing a high-end integral service system
          capable of providing complete service of construction design as well as construction
          technology with cultural creative ideas as the core.
        </p>
      </div>
    </div>
    <div class="about-team-container">
      <div class="about__cover-container scrollObj"></div>
      <ul class="about__course-list">
        <li v-for="(item, index) in course" :key="index" class="scrollObj" data-extend="number">
          <div class="list__item">
            <p class="item__nub">
              <span class="number__text" :data-end="item.nub"></span>
              <span v-if="item.extend">{{ item.extend }}</span>
            </p>
            <div class="item__content">
              <p>
                {{ item.content.cn }}
              </p>
              <p v-html="item.content.en"></p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="about-creator-container scrollObj">
      <div
        class="title skrollable dom-parallax"
        :class="{
          mobileMove: $bowserMobile
        }"
        data-bottom-top="transform:translateX(10%);"
        data-top-bottom="transform:translateX(-10%);"
      >
        BLVD FOUNDERS
      </div>
      <div
        class="creators-list-container scrollObj"
        data-extend="bg"
        data-color="#000"
        data-logo="1"
        v-if="creators"
      >
        <div
          class="creator__item scrollObj"
          ref="creatorItem"
          v-for="(item, index) in creators"
          :key="index"
          @click="showmore(item.id)"
          :data-id="item.id"
        >
          <div class="item__cover">
            <img :src="item.cover" alt="" />
          </div>
          <div class="item__info">
            <div class="info__text">
              <div class="text__name">
                <p>{{ item.name.cn }} {{ item.name.en }}</p>
                <div class="line" :class="[`queue${index}`]"></div>
              </div>
              <div class="text__other">
                <p>{{ item.title.cn }}</p>
                <p>{{ item.title.en }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition @before-enter="beforeEnter" @enter="enter1" @before-leave="beforeLeave">
        <div class="more-detail-container" v-if="clickedShow" ref="mdc">
          <div class="headPic scrollObj" v-if="$bowserMobile">
            <img :src="clickedShow.cover" alt="" />
          </div>
          <div class="back-bt mobile-bt scrollObj" @click="detailOnClose" v-if="$bowserMobile">
            <div class="line"></div>
            <p>back</p>
          </div>
          <div class="text__body cn scrollObj">
            <div class="title">
              <strong>{{ clickedShow.name.cn }}</strong> | <span>{{ clickedShow.title.cn }}</span>
            </div>
            <div class="job">
              <p v-for="(text, index) in clickedShow.jobs.cn.split('\n')" :key="index">
                {{ text }}
              </p>
            </div>
            <div class="content">
              <p v-for="(text, index) in clickedShow.summary.cn.split('\n')" :key="index">
                {{ text }}
              </p>
            </div>
            <div class="back-bt scrollObj" @click="detailOnClose" v-if="!$bowserMobile">
              <div class="line"></div>
              <p>back</p>
            </div>
          </div>
          <div class="text__body en scrollObj">
            <div class="title">
              <strong>{{ clickedShow.name.en }}</strong> | <span>{{ clickedShow.title.en }}</span>
            </div>
            <div class="job">
              <p v-for="(text, index) in clickedShow.jobs.en.split('\n')" :key="index">
                {{ text }}
              </p>
            </div>
            <div class="content">
              <p v-for="(text, index) in clickedShow.summary.en.split('\n')" :key="index">
                {{ text }}
              </p>
            </div>
          </div>
          <div class="headPic scrollObj" v-if="!$bowserMobile">
            <img :src="clickedShow.cover" alt="" />
          </div>
        </div>
      </transition>
    </div>
    <div
      class="about-brands-container scrollObj"
      data-extend="spbg"
      data-logo="1"
      data-offset="top"
      data-logoclass="red-logo-white-menu"
      v-if="brands"
    >
      <div class="brands-lists-container">
        <div class="brand__item show" v-for="(brand, index) in brands" :key="index">
          <div class="item__title">
            <p>{{ brand.name.en }}</p>
            <p>{{ brand.name.cn }}</p>
          </div>
          <div
            class="item__brand-wrapper"
            :style="{
              height: `${logoWrapperHeight.length > 0 ? logoWrapperHeight[index] : 0}px`
            }"
          >
            <div class="brand__main-body">
              <div class="brand__logo-item scrollObj" v-for="(logo, l) in brand.lists" :key="l">
                <img :src="logo.cover.colorful" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <page-footer class="withoutPadding withoutBorder inAbout"></page-footer>
    </div>
    <div
      class="v4-back-bt scrollObj"
      data-extend="fixedshow"
      data-relatedshow="creators-list-container"
      @click="scrollToTop"
    >
      <p class="line"></p>
      <p>back top</p>
    </div>
  </div>
</template>

<script>
import skrollr from '@/plugins/skrollr.min';
import { demo2init, demo2OnScroll } from '@/assets/utils/common';
import { mapState } from 'vuex';
import pageFooter from '../common/page-footer.vue';

export default {
  components: {
    pageFooter,
  },
  data() {
    return {
      course: [
        {
          nub: 20,
          content: {
            cn: '二十年设计逐梦之旅',
            en: 'years pursuing our<br/>design dream',
          },
        },
        {
          nub: 100,
          content: {
            cn: '一百多家国际知名品牌客户',
            en: 'worldwide acclaimed clients',
          },
          extend: '+',
        },
        {
          nub: 200,
          content: {
            cn: '二百多位优秀中外设计师团队',
            en: 'excellent designers<br/>from China and abroad',
          },
          extend: '+',
        },
        {
          nub: 4000,
          content: {
            cn: '四千公顷城市滨水岸线打造',
            en: 'hectares of urban waterfront<br/>landscape creation',
          },
          extend: '+',
        },
      ],
      creators: null,
      brands: null,
      currentIndex: 0,
      logoWrapperHeight: [],
      isClicked: false,
      clickedShow: null,
    };
  },
  computed: {
    ...mapState(['$bowserMobile']),
  },
  watch: {
    $route: {
      handler() {
        this.$store.commit('setBackgroundColor', '#fff');
        document.body.style.cssText = 'background-color:#fff;';
        this.getData();
      },
      immediate: true,
    },
    clickedShow(val) {
      if (val === null) {
        document.documentElement.classList.remove('overflowHidden');
      } else {
        document.documentElement.classList.add('overflowHidden');
      }
    },
  },
  created() {
    // for (let i = 1; i <= 80; i += 1) {
    //   if (i <= 56) {
    //     this.brands['1'].lists.push({
    //       url: `/images/logo/l${i}.jpg`,
    //     });
    //   } else {
    //     this.brands['0'].lists.push({
    //       url: `/images/logo/l${i}.jpg`,
    //     });
    //   }
    // }
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {
      if (!that.$bowserMobile) {
        skrollr.init({
          smoothScrolling: true,
          forceHeight: false,
        });
      }
    });
  },
  updated() {
    this.$nextTick(() => {
      demo2init();
      demo2OnScroll();
    });
  },
  destroyed() {
    document.documentElement.classList.remove('white-logo');
    document.documentElement.style.cssText = 'background-color:#fff';
    document.body.style.cssText = 'background-color:#fff;';
  },
  beforeRouteLeave(t, e, o) {
    document.body.classList.remove('white-logo');
    document.documentElement.style.cssText = 'background-color:#fff';
    document.body.style.cssText = 'background-color:#fff;';
    setTimeout(() => {
      o();
    }, 500);
  },
  methods: {
    onChangeSwitch(index) {
      this.currentIndex = index;
      this.isClicked = true;
    },
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    showmore(id) {
      this.clickedShow = this.creators.find((item) => Number(item.id) === Number(id));
      this.$refs.creatorItem.map((item) => {
        item.classList.add('hidden');
        return item;
      });
    },
    beforeEnter() {},
    enter1(e) {
      if (this.$bowserMobile) {
        setTimeout(() => {
          Array.from(e.querySelectorAll('.scrollObj')).forEach((item) => {
            item.classList.add('animated');
          });
        }, 300);
      }
    },
    beforeLeave() {},
    detailOnClose() {
      this.$refs.creatorItem.map((item) => {
        item.classList.remove('hidden');
        return item;
      });
      this.clickedShow = null;
    },
    getData() {
      const that = this;
      that.$fly
        .multi([
          that.$fly.get('/api/team/creators'),
          that.$fly.get('/api/team/brand-type'),
          that.$fly.get('/api/team/brands'),
        ])
        .then((res) => {
          const creators = res['0'].data.list;
          const brandTypes = res['1'].data;
          const brands = res['2'].data;
          that.creators = creators;
          that.brands = brandTypes.map((item) => {
            const temp = item;
            const brandLists = [];
            brands.forEach((brand) => {
              if (Number(brand.type_id) === Number(item.id)) {
                brandLists.push(brand);
              }
            });
            temp.lists = brandLists;
            return temp;
          });
          that.$nextTick(() => {
            const logoWrapper = document.querySelectorAll('.brand__main-body');
            that.logoWrapperHeight = Array.from(logoWrapper).map((item) => item.offsetHeight);
          });
        });
    },
  },
};
</script>

<style>
body {
  background-color: #fff;
}
</style>
